@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');


.home {
  width: 100%;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.3);
   /* Updated background color */
}

/* General layout for home container */
.home-container {
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: space-between; /* Space between image and text */
  padding: 20px; /* Add padding as needed */
  height: 80vh;
  background-image: url("../assets/images/canvabg2.png");
}

/* Image container */
.image-container {
  width: 50%; /* Adjust width as needed */
}

.app-image {
  width: 80%; /* Adjust size of the image */
  max-width: 400px; /* Limit max size */
}

/* Text container */
.text-container {
  width: 70%; /* Adjust width as needed */
  display: flex;
  flex-direction: column; /* Stack title, subtitle, buttons */
  justify-content: center; /* Center content vertically */
}

/* Title and subtitle styles */
.title {
  font-size: 2em; /* Adjust size as needed */
  color: #EF8214; /* Set title color to orange */
  text-align: left; /* Align text to the left */
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.subtitle {
  font-size: 1.2em; /* Adjust size as needed */
  color: black; /* Set subtitle color to black */
  text-align: left; /* Align text to the left */
  font-family: 'Outfit', sans-serif;
  font-weight: 300;
  font-style: normal;
}

/* Button container styles */
.button-container {
  display: flex;
  flex-direction: row;
  gap: 20px; /* Space between buttons */
  margin-top: 5vh; /* Space above buttons */
}

/* Download button styles */
.download-button {
  width: 200px; /* Set button width */
  height: 60px; /* Set button height */
  background-size: contain; /* Scale the background image */
  background-repeat: no-repeat; /* Prevent background image repeat */
  border: none; /* Remove default border */
  cursor: pointer; /* Change cursor to pointer */
  color: transparent; /* Make text invisible if using images */
  outline: none; /* Remove outline */
}

/* Responsive styles */
@media (max-width: 768px) {
  .home-container {
    flex-direction: column; /* Stack content vertically on smaller screens */
    align-items: center; /* Center content */
  }

  .image-container, .text-container {
    width: 100%; /* Full width on small screens */
    text-align: center; /* Center text */
  }

  .app-image {
    width: 70%; /* Adjust size of the image on small screens */
  }

  .button-container {
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Space between buttons */
  }

  .download-button {
    width: 90%; /* Full width for buttons */
  }
}