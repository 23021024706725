/* .top-banner {
    background-color: #ff4500;
    color: white;
    text-align: center;
    padding: 5px 0;
    font-size: 14px;
  }
  
  .navbar {
    display: flex;
    justify-content: center;
    background-color: #333;
    padding: 10px 0;
  }
  
  .nav-item {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    font-size: 16px;
  }
  
  .nav-item:hover {
    color: #ff4500;
  } */

/* @font-face {
  font-family: 'Outfit', sans-serif;
  src: url('../assets/font/Outfit-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  src: url('../assets/font/Outfit-Light.ttf') format('truetype');
} */
/* Download button and dropdown */
/* Container styling */
/* Container styling */
/* General header styling */
.container {
  padding: 10px 5%;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: rgb(252, 250, 251);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures space between logo and nav */
}

/* Main nav styling */
nav {
  width: 100%;
  display: flex;
  align-items: center;
}

/* Flex container for nav items and download button */
.nav-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

/* Styling for the logo */
.logo {
  width: 15%;
  cursor: pointer;
}

/* Navigation list styling */
nav ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin: 0;
}

nav ul li {
  margin-right: 20px; /* Space between items */
}

nav ul li a {
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
  color: #000;
  text-decoration: none;
  font-size: 18px;
  position: relative;
}

/* Hover underline effect for the menu items */
nav ul li a::after {
  content: '';
  width: 0;
  height: 3px;
  background: #EF8214;
  position: absolute;
  left: 0;
  bottom: -6px;
  transition: 0.5s;
}

nav ul li a:hover::after {
  width: 100%;
}

/* Download button container */
.download-container {
  position: relative;
  margin-left: 20px;
}

/* Styling for the download button */
.download-btn {
  background-color: #EF8214;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
  font-size: 16px;
}

/* Dropdown menu for the download button */
.download-dropdown {
  display: none;
  padding: 3px 8px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  right: 0;
}

.download-dropdown a {
  color: black;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  font-family: 'Outfit', sans-serif;
  font-weight: 300;
}

.download-dropdown a:hover {
  background-color: #f1f1f1;
}

.download-container:hover .download-dropdown {
  display: block;
}

/* Styling for the dog gif */
.dog-gif {
  height: 100%;
  object-fit: contain;
  position: absolute;
  animation: running-dog 10s linear infinite;
  z-index: -1;
}

/* Keyframes for the dog animation */
@keyframes running-dog {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(1400px);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  nav {
    width: 100%;
    justify-content: space-between;
  }

  .logo {
    width: 40%;
  }

  nav ul {
    width: auto;
    padding: 0;
    text-align: right;
  }

  nav ul li {
    display: block;
    padding: 10px 0;
  }

  .download-container {
    margin-top: 5px;
  }


  @keyframes running-dog {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  .dog-gif {
    height: 100%;
    object-fit: contain;
    position: absolute;
    animation: running-dog 2s linear infinite;
    z-index: -1;
  }
}

body {
  width: 100%;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}