@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

.services {
    text-align: center;
    padding: 50px;
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.services-header {
    max-width: 50%;
    margin-bottom: 50px;
}

.services-header h1 {
    color: #333;
    font-size: 32px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
}

.service-cards {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 40px;
    margin-bottom: 50px;
}

/* Common card styles */
.service-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-height: 200px;
    transition: transform 0.3s ease;
    overflow: hidden;
}

.service-card .card-text {
    flex: 1;
    padding: 20px;
}

.service-card .card-text h3 {
    font-family: 'Outfit', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: #333;
}

.service-card .card-text p {
    font-family: 'Outfit', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #666;
    margin-top: 10px;
}

.service-card .card-image {
    flex: 0.4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-card .card-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #EF8214;
}

/* Alternating layout - Image right, text left */


/* Responsive design */
@media (max-width: 768px) {
    .service-card {
        flex-direction: column;
        text-align: center;
    }

    .service-card .card-text {
        padding: 10px 0;
    }

    .service-card .card-image {
        margin-bottom: 20px;
    }
    .service-card.grooming, .service-card.pet-taxi {
      flex-direction: column-reverse; /* Ensures the image is on top on mobile */
  }
}

@media (max-width: 960px) {
    .services-header h1 {
        font-size: 28px;
    }

    .service-cards {
        width: 90%;
    }

    .service-card {
        padding: 15px;
    }
}