@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

/* Existing Startup Article Styles */
.startup-article-wrapper {
  display: flex; /* Ensures image and text are in a row */
  align-items: center; /* Align items vertically */
  margin: 50px auto; /* Adjust margins as needed */
  width: 80%; /* Adjust width as needed */
  overflow: hidden; /* Clear floats */
}

.startup-article-image {
  max-width: 40%; /* Adjust image width */
  height: auto; /* Maintain aspect ratio */
  margin-right: 20px; /* Space between image and text */
}

.startup-text-wrapper {
  flex-grow: 1; /* Take the remaining space next to the image */
  text-align: left; /* Align text to the left */
  display: flex;
  flex-direction: column; /* Stack text elements vertically */
  justify-content: center; /* Center the content vertically */
  height: 100%; /* Ensure it takes the full height of the wrapper */
}

/* Change body text color to black */
.startup-article h1,
.startup-article p,
.startup-link {
  color: black; /* Set text color to black */
}

/* Read more link styles */
.startup-link {
  display: inline-block;
  margin-top: 10px;
  font-weight: 600;
  text-decoration: none;
  transition: color 0.3s ease;
}

.startup-link:hover {
  color: #EF8214; /* Orange color on hover */
}

.blog-section {
  padding: 50px 20px;
  background-color: #f8f8f8;
  color: #333;
}

.blog-header {
  text-align: center;
  margin-bottom: 2rem;
  color: #333; /* The color for the main title */
  font-size: 32px;
  margin-bottom: 3vh;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.blog-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.blog-title {
  font-family: 'Outfit', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.blog-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto !important;
}

.blog-description {
  margin: 1rem 0;
}

@media (max-width: 960px) {
  .blog-card {
    width: calc(50% - 20px); 
  }
}

@media (max-width: 768px) {
  .blog-card {
    width: 100%;
    margin-bottom: 20px;
  }
}
