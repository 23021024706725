.signup-form {
    text-align: center;
    /* padding: 20px; */
    padding: 7% 0%;
    background-color: #f0f7fc;
    background-image: url("../assets/images/mailing-list-bg.svg");
    background-size:contain;
    background-repeat: no-repeat;
}


.signup-form .email-input {
    margin: 10px 0;
    width: 250px;
}

.signup-form .submit-button {
    margin-top: 20px;
    /* margin-left: 5%; */
}

.success-message {
    color: #50fa7b;
}

.sign-up {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 30%;
}

@media (max-width: 768px) {
    .signup-form {
        margin-top: 30em;
        padding-top: 10em;
    }
}