@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

.footer {
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.2); /* Add box shadow to footer */
  padding: 20px;
  background-image: url("../assets/images/footernew.svg");
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: 10% center;
  position: relative;
}

.prdog {
  width: 20%; /* Adjust as needed */
  height: auto;
  float: left; /* Align the image to the left */
  position: absolute;
  top: 0.1%;
  left: 0.1%;
}

.social-media {
  display: flex;
  justify-content: center;
  gap: 2%;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.social-media a {
  transition: transform 0.3s ease-in-out; 
}

.social-media a:hover {
  transform: translateY(-10px); 
}

.payment-icons {
  display: flex;
  justify-content: center;
  gap: 2.5%;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.payment-icon {
  width: 4%;
  height: 4%;

}



.footer-links {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #000000;
  padding-bottom: 20px;
}

.footer-links p {
  color: rgb(0, 0, 0);
  text-decoration: none;
  margin: 0 10px;
  font-size: 16px;
  font-family: 'Outfit', sans-serif;
    font-weight: 300;
    font-style: normal;
}

.footer-links a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  margin: 0 10px;
  font-size: 16px;
  font-family: 'Outfit', sans-serif;
  font-weight: 300;
}

.footer-links a:hover {
  text-decoration: underline;
}


/* .footer-links p:hover {
  text-decoration: underline;
} */

.footer-links p svg {
  vertical-align: text-bottom; 
}

.footer-company-name {
  font-family: 'Outfit', sans-serif;
  font-weight: bold;
  font-size: 18px;
}

@media (max-width: 768px) {
  .footer-links {
    flex-direction: column; /* Stack the links vertically */
    align-items: center; /* Center align the items */
    text-align: center; /* Center align the text */
  }

  .footer-links a,
  .footer-links p {
    display: block; /* Make each link and paragraph take its own line */
    margin-bottom: 10px; /* Add some space between each link/paragraph */
  }

  .payment-icon {
    width: 6%; /* Adjust the width to make icons larger */
    height: auto; /* Maintain aspect ratio */
  }
}
